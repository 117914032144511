import React, { useState, useEffect } from "react";
import CalcPayment from "./CalcPayment";
import { useLanguage } from "../Header/LanguageContext";
import RangeInput from "./RangeInput"; // Импорт компонента RangeInput

function CalcFormula(props) {
  const [sum, setSum] = useState(0);
  const [month, setMonth] = useState(0);
  const [type, setType] = useState("");

  useEffect(() => {
    setSum(0);
    setMonth(0);
    setType(props.props.type);
  }, [props]);

  const { language } = useLanguage();

  const texts = {
    EN: {
      title: "Set parameters:",
      amount: "Amount, MDL",
      months: "Number of months",
    },
    RO: {
      title: "Setează parametrii:",
      amount: "Suma, MDL",
      months: "Numărul de luni",
    },
    RU: {
      title: "Установите параметры:",
      amount: "Сумма, MDL",
      months: "Количество месяцев",
    },
  };

  const { title, amount, months } = texts[language] || texts.EN;

  const handleSliderChange = (e, setNum) => {
    setNum(e.target.value);
  };

  const hasValues = sum > 0 && month > 0;

  return (
    <div className="formula">
      <h5 className="margin">{title}</h5>
      {/* Использование компонента RangeInput для ввода суммы */}
      <RangeInput
        label={amount}
        id="amountRange"
        max={props.props.sum}
        step={1000}
        value={sum}
        onChange={(e) => handleSliderChange(e, setSum)}
      />
      {/* Использование компонента RangeInput для ввода количества месяцев */}
      <RangeInput
        label={months}
        id="monthRange"
        max={props.props.month}
        step={1}
        value={month}
        onChange={(e) => handleSliderChange(e, setMonth)}
      />
      {hasValues && (
        <div className="formula">
          <CalcPayment props={{ sum, month, type }} />
        </div>
      )}
    </div>
  );
}

export default CalcFormula;
