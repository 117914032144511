import React from "react";
import ContactForm from "./ContactForm";
import { useLanguage } from "../Header/LanguageContext"; // Импортируем useLanguage из контекста

const Feedback = () => {
  const { language } = useLanguage(); // Получаем текущий язык из контекста

  return (
    <>
      {language === "RO" && (
        <>
          <div className="row">
            <div className="col s12">
              <div
                className="card-panel blue-grey"
                style={{ marginBottom: "0px", marginTop: "0px" }}
              >
                <span className="white-text">
                  În prezent, trimiterea calculelor nu este disponibilă. Vă
                  rugăm să sunați pentru informații suplimentare sau consultanță
                  la numărul: +373 680 83 435.
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div
                className="card-panel blue-grey"
                style={{ marginBottom: "0px", marginTop: "0px" }}
              >
                <span className="white-text">
                  Puteți, de asemenea, să lăsați datele dvs. și vă vom contacta:
                  <ContactForm />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {language === "RU" && (
        <>
          <div className="row">
            <div className="col s12">
              <div
                className="card-panel blue-grey"
                style={{ marginBottom: "0px", marginTop: "0px" }}
              >
                <span className="white-text">
                  В настоящее время отправка расчетов недоступна. Пожалуйста,
                  позвоните по телефону для получения дополнительной информации
                  или консультации: +373 680 83 435.
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col s12">
              <div
                className="card-panel blue-grey"
                style={{ marginBottom: "0px", marginTop: "0px" }}
              >
                <span className="white-text">
                  Вы также можете оставить свои данные, и мы с Вами свяжемся:
                  <ContactForm />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Feedback;
