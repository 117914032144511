import React, { useEffect } from "react";
import { useLanguage } from "./LanguageContext";
import NavBar from "./NavBar";
import M from "materialize-css"; // Импортируем Materialize для инициализации бокового меню

const Header = () => {
  const { language, setLanguage } = useLanguage();

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  useEffect(() => {
    // Инициализация бокового меню после рендеринга компонента
    const sidenav = document.querySelector(".sidenav");
    M.Sidenav.init(sidenav);
  }, []);

  useEffect(() => {
    // Инициализация dropdown-trigger после рендеринга компонента
    const dropdowns = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(dropdowns);
  }, []);
  const logoStyles = {
    maxHeight: "100%", // Устанавливаем максимальную высоту логотипа
    width: "auto", // Ширина автоматически рассчитывается для сохранения пропорций
  };
  return (
    <header className="header">
      <ul id="dropdown1" className="dropdown-content">
        <li>
          <a href="/regulament001.pdf">
            {language === "RU" ? "Регламент 1" : "Regulamente 1"}
          </a>
        </li>
        <li>
          <a href="/regulament002.pdf">
            {language === "RU" ? "Регламент 2" : "Regulamente 2"}
          </a>
        </li>
        <li>
          <a href="/Autorizare.pdf">
            {language === "RU" ? "Авторизация" : "Autorizare"}
          </a>
        </li>
      </ul>
      <nav className="nav orange accent-3">
        <div className="nav-wrapper">
          <img src="/logo.png" alt="asicredinvest" style={logoStyles} />
          <a href="#" className="brand-logo">
            asicredinvest
          </a>
          <a href="#" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons">menu</i>
          </a>
          <ul className="right">
            <li>
              <button
                className="btn-flat"
                onClick={() => handleLanguageChange("RU")}
              >
                RU
              </button>
            </li>
            <li>
              <button
                className="btn-flat"
                onClick={() => handleLanguageChange("RO")}
              >
                RO
              </button>
            </li>
          </ul>
          <ul className="right hide-on-med-and-down">
            <li>
              <a className="dropdown-trigger" href="#" data-target="dropdown1">
                {language === "RU" ? "Документация" : "Documentation"}
                <i className="material-icons right">arrow_drop_down</i>
              </a>
            </li>
            <li>
              <a href="#">{language === "RU" ? "Контакты" : "Contacts"}</a>
            </li>
          </ul>
        </div>
      </nav>

      <ul className="sidenav" id="mobile-demo">
        <li>
          <a href="#">{language === "RU" ? "Документация" : "Documentation"}</a>
        </li>
        <li>
          <a href="/regulament001.pdf">
            {language === "RU" ? "Регламент 1" : "Regulamente 1"}
          </a>
        </li>
        <li>
          <a href="/regulament002.pdf">
            {language === "RU" ? "Регламент 2" : "Regulamente 2"}
          </a>
        </li>
        <li>
          <a href="/Autorizare.pdf">
            {language === "RU" ? "Авторизация" : "Autorizare"}
          </a>
        </li>
        <li>
          <a href="#">{language === "RU" ? "Контакты" : "Contacts"}</a>
        </li>

        <li>
          <button
            className="btn-flat"
            onClick={() => handleLanguageChange("RU")}
          >
            RU
          </button>
        </li>
        <li>
          <button
            className="btn-flat"
            onClick={() => handleLanguageChange("RO")}
          >
            RO
          </button>
        </li>
      </ul>
    </header>
  );
};

export default Header;
