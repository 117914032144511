import React, { useState, useEffect } from "react";
import { send } from "emailjs-com";
import { useLanguage } from "../Header/LanguageContext";
import M from "materialize-css"; // импорт Materialize

function ContactForm() {
  const { language } = useLanguage();

  const [toSend, setToSend] = useState({
    to_name: "",
    to_phone: "",
    to_idnp: "",
    reply_to: "",
    message: "",
  });

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // Track checkbox state

  const handleBlur = (e) => {
    if (e.target.name === "to_idnp" && e.target.value.length !== 13) {
      setError(
        language === "RO"
          ? "IDNP-ul trebuie să conțină exact 13 cifre."
          : "ИДНП должен содержать ровно 13 цифр."
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      toSend.to_name.trim() === "" ||
      toSend.to_phone.trim() === "" ||
      toSend.reply_to.trim() === "" ||
      toSend.to_idnp.trim() === "" ||
      toSend.to_idnp.length !== 13
    ) {
      setError(
        language === "RO"
          ? "Vă rugăm să completați toate câmpurile corect."
          : "Пожалуйста, заполните все поля корректно."
      );
      return;
    }

    if (!isCheckboxChecked) {
      setError(
        language === "RO"
          ? "Vă rugăm să acceptați termenii și condițiile pentru a continua."
          : "Пожалуйста, примите условия и положения, чтобы продолжить."
      );
      return;
    }

    setError("");
    send("service_56iysag", "template_eopfs2v", toSend, "xlWEdlHqIlhNGEHfS")
      .then((response) => {
        if (response.status === 200) {
          setSuccessMessage(
            language === "RO"
              ? "Trimiterea a fost efectuată cu succes!"
              : "Отправка прошла успешно!"
          );
          setToSend({
            to_name: "",
            to_phone: "",
            to_idnp: "",
            reply_to: "",
            message: "",
          });
          setIsCheckboxChecked(false); // Reset checkbox state
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "to_idnp" && isNaN(e.target.value)) {
      return;
    }
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    setError("");
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    setError(""); // Clear any existing error when the checkbox state changes
  };
  useEffect(() => {
    // Инициализируем модальное окно при загрузке компонента
    const modal = document.getElementById("modal2");
    M.Modal.init(modal);
  }, []); // Пустой массив зависимостей гарантирует, что это выполнится только при монтировании

  const openModal = () => {
    const modal = document.getElementById("modal2");
    const instance = M.Modal.getInstance(modal);
    instance.open();
  };
  return (
    <div className="row">
      <form className="col s12" onSubmit={onSubmit}>
        <div className="row">
          <div className="input-field col s6">
            <input
              id="to_name"
              type="text"
              className={`validate ${error && "invalid"}`}
              value={toSend.to_name}
              onChange={handleChange}
              name="to_name"
            />
            <label htmlFor="to_name">
              {language === "RO" ? "Numele dumneavoastră" : "Ваше имя"}
            </label>
          </div>

          <div className="input-field col s6">
            <input
              id="to_phone"
              type="text"
              className="validate"
              value={toSend.to_phone}
              onChange={handleChange}
              name="to_phone"
            />
            <label htmlFor="to_phone">
              {language === "RO" ? "Telefonul dumneavoastră" : "Ваш телефон"}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <input
              className={`validate ${error && "invalid"}`}
              id="to_idnp"
              type="text"
              value={toSend.to_idnp}
              onChange={handleChange}
              onBlur={handleBlur}
              name="to_idnp"
            />
            <label htmlFor="to_idnp">
              {language === "RO" ? "IDNP-ul dumneavoastră" : "IDNP-ul номер"}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <input
              id="reply_to"
              type="email"
              className="validate"
              value={toSend.reply_to}
              onChange={handleChange}
              name="reply_to"
            />
            <label htmlFor="reply_to">
              {language === "RO"
                ? "Adresa dvs. de email"
                : "Ваш адрес электронной почты"}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12">
            <textarea
              id="message"
              className="materialize-textarea"
              value={toSend.message}
              onChange={handleChange}
              name="message"
            />
            <label htmlFor="message">
              {language === "RO" ? "Comentariul dvs." : "Ваш комментарий"}
            </label>
          </div>
        </div>
        {language === "RO" && (
          <>
            <label>
              <input
                type="checkbox"
                onChange={handleCheckboxChange} // Add event handler for checkbox
              />
              <span>Prin trimiterea datelor personale, oferiți</span>{" "}
            </label>
            <span
              id="openModalText"
              onClick={openModal} // Вызывайте функцию openModal при клике
            >
              consimțământul pentru prelucrarea și stocarea datelor
            </span>
          </>
        )}
        {language === "RU" && (
          <>
            <label>
              <input
                type="checkbox"
                onChange={handleCheckboxChange} // Add event handler for checkbox
              />
              <span>Отправляя личные данные, вы даете </span>{" "}
            </label>
            <span
              id="openModalText"
              onClick={openModal} // Вызывайте функцию openModal при клике
            >
              согласие на обработку и хранение данных
            </span>
          </>
        )}

        <p>
          <span className="helper-text" data-error={error}>
            {error}
          </span>
        </p>
        <button
          type="submit"
          className="waves-effect waves-light btn"
          disabled={!isCheckboxChecked} // Disable button if the checkbox is not checked
        >
          {language === "RO" ? "Trimite" : "Отправить"}
        </button>
        <p>
          {successMessage && (
            <span className="success-message">{successMessage}</span>
          )}
        </p>
        <div></div>
      </form>
      <div id="modal2" className="modal">
        <div className="modal-content black-text">
          <p>
            {language === "RO"
              ? "Ne rezervăm dreptul de a stoca și procesa datele dvs. pe termen nelimitat."
              : "Оставляем за собой право хранить и обрабатывать ваши данные бессрочно."}
          </p>
          <p>
            {language === "RO"
              ? "Aveți dreptul de a revoca permisiunea de a stoca și prelucra date cu caracter personal în orice moment, la cerere personală"
              : "Вы имеете право отозвать разрешение на хранение и обработку личных данных в любое время по личному запросу"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
