import React, { useState } from "react";
import CalcSelect from "./CalcSelect";
import Feedback from "./Feedback";
import { useLanguage } from "../Header/LanguageContext";

function Calculator() {
  const { language } = useLanguage();
  const [showFeedback, setShowFeedback] = useState(false);

  return (
    <div className="App">
      <div className="container">
        <div className="div">
          {!showFeedback && (
            <div className="col">
              <CalcSelect />
              <a
                className="waves-effect waves-light btn"
                onClick={() => setShowFeedback(true)}
              >
                <i className="material-icons right">arrow_forward</i>
                {language === "RO" ? "Continuă" : "Продолжить"}
              </a>
            </div>
          )}
          {showFeedback && (
            <div>
              <Feedback />
              <a
                className="waves-effect waves-light btn"
                onClick={() => setShowFeedback(false)}
              >
                <i className="material-icons left">arrow_back</i>
                {language === "RO" ? "Pentru a reveni" : "Вернуться"}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Calculator;
