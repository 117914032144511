const Products = [
  {
    id: 1,
    name_ro: "Credit",
    name_ru: "Кредит",
    value: "product1",
    types: [
      {
        id: 1,
        name_ro: "Rapid",
        name_ru: "Быстрый",
        type: "Type11",
        sum: 60000,
        month: 60,
      },
      {
        id: 2,
        name_ro: "Cu garanții mobiliare",
        name_ru: "Под залог",
        type: "Type12",
        sum: 500000,
        month: 60,
      },
      {
        id: 3,
        name_ro: "Cu garanții imobiliare",
        name_ru: "Под залог недвижимого имущества",
        type: "Type13",
        sum: 1000000,
        month: 60,
      },
      /*{
        id: 3,
        name_ro: "Cu garanții imobiliare",
        name_ru: "Ипотека",
        type: "Type13",
        sum: 1500000,
        month: 120,
      },*/
    ],
  },
  {
    id: 2,
    name_ro: "Asigurare",
    name_ru: "Страхование",
    value: "product2",
    types: [
      {
        id: 4,
        name_ro: "AORC (asigurare obligatorie de răspundere civilă internă)",
        name_ru: "ОСАГО",
        type: "Type21",
      },
      {
        id: 5,
        name_ro: "CASCO (asigurare auto facultativă)",
        name_ru: "КАСКО",
        type: "Type22",
      },
      {
        id: 6,
        name_ro:
          "Carte Verde (asigurare obligatorie de răspundere auto externă)",
        name_ru: "Зеленая карта",
        type: "Type23",
      },
    ],
  },
  {
    id: 3,
    name_ro: "Servicii gaj",
    name_ru: "Гарантия",
    value: "product3",
    types: [
      {
        id: 7,
        name_ro: "Servicii înregistrare gaj",
        name_ru: "Оформление гарантий",
        type: "Type31",
      },
      {
        id: 8,
        name_ro: "Servicii modificare gaj existent",
        name_ru: "Изменение существующего залога",
        type: "Type32",
      },
      {
        id: 9,
        name_ro: "Servicii radiere garanții existente",
        name_ru: "Прекращение залога",
        type: "Type33",
      },
    ],
  },
];

export default Products;
