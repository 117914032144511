import React, { useState } from "react";
import Products from "./Products";
import CalcFormula from "./CalcFormula";
import { useLanguage } from "../Header/LanguageContext";

function CalcSelect() {
  const { language } = useLanguage();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const handleProductChange = (product) => {
    setSelectedProduct(product);
    setSelectedProductId(product.value);
    setSelectedType(null);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  return (
    <div className="col s12">
      <h5>{language === "RO" ? "Alegeți un produs:" : "Выберите продукт:"}</h5>
      <form action="#">
        {Products.slice()
          .reverse()
          .map(
            (
              product // Используем slice() и reverse()
            ) => (
              <p key={product.id} className="col s4">
                <label>
                  <input
                    name="selectedProduct"
                    type="radio"
                    checked={selectedProduct === product}
                    onChange={() => handleProductChange(product)}
                  />
                  <span>
                    {language === "RO" ? product.name_ro : product.name_ru}
                  </span>
                </label>
              </p>
            )
          )}
      </form>
      {selectedProduct && (
        <div>
          <h5>{language === "RO" ? "Alegeți un tip:" : "Выберите тип:"}</h5>
          <form action="#">
            {selectedProduct.types.map((type) => (
              <p key={type.id} className="col s4">
                <label>
                  <input
                    name="selectedType"
                    type="radio"
                    checked={selectedType === type}
                    onChange={() => handleTypeChange(type)}
                  />
                  <span>{language === "RO" ? type.name_ro : type.name_ru}</span>
                </label>
              </p>
            ))}
          </form>
        </div>
      )}

      {selectedProductId === "product1" && selectedType ? (
        <div>
          <CalcFormula props={selectedType} />
        </div>
      ) : null}
    </div>
  );
}

export default CalcSelect;
