import React, { useEffect } from "react"; // обязательный импорт useEffect
import M from "materialize-css"; // импорт Materialize
import { useLanguage } from "../Header/LanguageContext";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// Import the Roboto font files
import RobotoRegular from "../fonts/Roboto-Regular.ttf";
import RobotoBold from "../fonts/Roboto-Bold.ttf";
import RobotoItalic from "../fonts/Roboto-Italic.ttf";
import RobotoBoldItalic from "../fonts/Roboto-BoldItalic.ttf";
// Register the Roboto font with react-pdf

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular },
    { src: RobotoBold, fontWeight: "bold" },
    { src: RobotoItalic, fontStyle: "italic" },
    { src: RobotoBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "Roboto",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    alignItems: "center",
    height: 24,
  },
  columnHeader: {
    width: "20%",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  cell: {
    width: "20%",
    textAlign: "center",
    fontFamily: "Roboto",
  },
});

const TablePDF = ({ schedule, language }) => (
  <Document>
    <Page size="A4">
      <View style={styles.page}>
        <Text style={styles.header}>
          {language === "RO"
            ? "Annuity Credit Calculation"
            : "Расчет аннуитетного кредита"}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.columnHeader}>
              {language === "RO" ? "Period" : "Период"}
            </Text>
            <Text style={styles.columnHeader}>
              {language === "RO" ? "Annuity" : "Аннуитет"}
            </Text>
            <Text style={styles.columnHeader}>
              {language === "RO" ? "Principal" : "Тело кредита"}
            </Text>
            <Text style={styles.columnHeader}>
              {language === "RO" ? "Interest" : "Процент"}
            </Text>
            <Text style={styles.columnHeader}>
              {language === "RO" ? "Balance" : "Остаток"}
            </Text>
          </View>
          {schedule.map((row) => (
            <View style={styles.tableRow} key={row.period}>
              <Text style={styles.cell}>{row.period}</Text>
              <Text style={styles.cell}>{row.payment}</Text>
              <Text style={styles.cell}>{row.principal}</Text>
              <Text style={styles.cell}>{row.interest}</Text>
              <Text style={styles.cell}>{row.balance}</Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const CalcPayment = (props) => {
  const { sum, month, type } = props.props;
  const { language } = useLanguage(); // Получаем текущий выбранный язык

  const annualPercentage = type === "Type11" ? 48 : type === "Type12" ? 36 : 24;
  const monthlyPercentage = annualPercentage / 12 / 100;

  const annuity =
    (sum * monthlyPercentage * Math.pow(1 + monthlyPercentage, month)) /
    (Math.pow(1 + monthlyPercentage, month) - 1);

  let balance = sum;
  const schedule = [];
  for (let i = 0; i < month; i++) {
    const interest = balance * monthlyPercentage;
    const payment = annuity - interest;
    balance -= payment;

    schedule.push({
      period: i + 1,
      payment: annuity.toFixed(2),
      principal: payment.toFixed(2),
      interest: interest.toFixed(2),
      balance: balance.toFixed(2),
    });
  }

  const n = 12; // Number of compounding periods per year
  const effectivePercentage = ((1 + annualPercentage / 100 / n) ** n - 1) * 100;

  useEffect(() => {
    // Инициализация Materialize при монтировании компонента
    const modals = document.querySelectorAll(".modal");
    M.Modal.init(modals);

    // Очистка при размонтировании компонента
    return () => {
      const modalInstances = M.Modal.getInstance(modals);
      if (modalInstances) {
        modalInstances.destroy();
      }
    };
  }, []);
  return (
    <div>
      <button
        className="waves-effect waves-orange btn modal-trigger orange darken-3 "
        data-target="modal1"
      >
        {language === "RO" ? "Vizualizați calculul" : "Посмотреть расчет"}
      </button>
      <p></p>
      <div id="modal1" className="modal">
        <div className="modal-content">
          <p>
            {language === "RO"
              ? `Procentul creditului: ${annualPercentage}%`
              : `Процент кредита: ${annualPercentage}%`}
          </p>
          <p>
            {language === "RO"
              ? `Rata anuală efectivă: ${effectivePercentage.toFixed(2)}%`
              : `Эффективная процентная ставка: ${effectivePercentage.toFixed(
                  2
                )}%`}
          </p>
          <p>
            {language === "RO"
              ? `Numărul de zile a creditului: ${month * 30}`
              : `Количество дней кредита: ${month * 30}`}
          </p>
          <p>
            {language === "RO"
              ? `Numărul de luni a creditului: ${month}`
              : `Количество месяцев кредита: ${month}`}
          </p>
          <p>
            {language === "RO"
              ? `Suma totală a creditului: ${sum}`
              : `Общая сумма кредита: ${sum}`}
          </p>
          <h2>
            {language === "RO"
              ? "Calculul creditului anuitar"
              : "Расчет аннуитетного кредита"}
          </h2>
          <table className="table">
            <thead>
              <tr className="table_title">
                <th>{language === "RO" ? "Perioadă" : "Период"}</th>
                <th>{language === "RO" ? "Anuitate" : "Аннуитет"}</th>
                <th>
                  {language === "RO" ? "Sumă de rambursat" : "Тело кредита"}
                </th>
                <th>{language === "RO" ? "Procent" : "Процент"}</th>
                <th>{language === "RO" ? "Rest" : "Остаток"}</th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((row) => (
                <tr key={row.period}>
                  <td>{row.period}</td>
                  <td>{row.payment}</td>
                  <td>{row.principal}</td>
                  <td>{row.interest}</td>
                  <td>{row.balance}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <PDFDownloadLink
            document={<TablePDF schedule={schedule} language={language} />}
            fileName="credit_calculation.pdf"
          >
            {({ loading }) =>
              loading
                ? language === "RO"
                  ? "Se încarcă PDF..."
                  : language === "RU"
                  ? "Загрузка PDF..."
                  : "Loading PDF..."
                : language === "RO"
                ? "Descarcă PDF"
                : "Скачать PDF"
            }
          </PDFDownloadLink>
        </div>
        <div className="modal-footer">
          <button className="modal-close waves-effect waves-green btn-flat">
            {language === "RO" ? "Închide" : "Закрыть"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalcPayment;
