import React from "react";
import { useLanguage } from "./Header/LanguageContext";

const IconContainer = () => {
  const { language } = useLanguage();

  return (
    <div className="container">
      <div className="section">
        <div className="row">
          <div className="col s12 m4">
            <div className="icon-block">
              <h2 className="center grey-text text-darken-4">
                <i className="material-icons">monetization_on</i>
              </h2>
              <h5 className="center">
                {language === "RU"
                  ? "Гарантируем финансовую уверенность"
                  : "Garantăm încrederea financiară"}
              </h5>
              <p className="light">
                {language === "RU"
                  ? "Максимально выгодные условия кредитования, разнообразные страховые пакеты и надежное оформление гарантийных обязательств"
                  : "Cele mai favorabile condiții de credit, o varietate de pachete de asigurare și executarea fiabilă a obligațiilor de garanție"}
              </p>
            </div>
          </div>

          <div className="col s12 m4">
            <div className="icon-block">
              <h2 className="center grey-text text-darken-4">
                <i className="material-icons">group</i>
              </h2>
              <h5 className="center">
                {language === "RU"
                  ? "Ваши риски - наша забота"
                  : "Riscurile dvs. sunt preocuparea noastră"}
              </h5>
              <p className="light">
                {language === "RU"
                  ? "Обширный выбор страховых продуктов Каско, ОСАГО, зеленая карта"
                  : "Selecție extinsă de produse de asigurare Casco, CTP, carte verde"}
              </p>
            </div>
          </div>

          <div className="col s12 m4">
            <div className="icon-block">
              <h2 className="center grey-text text-darken-4">
                <i className="material-icons">settings</i>
              </h2>
              <h5 className="center">
                {language === "RU"
                  ? "Полный спектр финансовых решений"
                  : "Gama completă de soluții financiare"}
              </h5>
              <p className="light">
                {language === "RU"
                  ? "Предоставляем кредиты под залог, прозрачные и выгодные ипотечные условия, а также оперативное оформление, изменение и прекращение гарантийных обязательств"
                  : "Oferim împrumuturi garantate, condiții ipotecare transparente și favorabile, precum și înregistrarea promptă, modificarea și încetarea obligațiilor de garanție"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default IconContainer;
