import React from "react";

const NavBar = () => {
  return (
    <div className="navBar orange accent-3">
      <i className="material-icons">contact_phone</i>{" "}
      <div style={{ marginLeft: "5px" }}>+373 680 83 435</div>
    </div>
  );
};

export default NavBar;
