import React from "react";

function RangeInput({ label, id, max, step, value, onChange }) {
  return (
    <form action="#">
      <div className="range-field">
        <label>{label}:</label>
        <input
          type="range"
          id={id}
          min={0}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
        />
        <output className="right-align">{value}</output>
      </div>
    </form>
  );
}

export default RangeInput;
