import React from "react";
import Calculator from "./Calculator/Calculator";
/*import Banner from "./Banner";*/
import Carousel from "./Carousel";
import CnpfSait from "./CnpfSait";
import IconContainer from "./IconContainer";
import Map from "./Map";

const Main = () => {
  return (
    <main className="main">
      <div className="row">
        <div className="col s12 m6">
          <Calculator />
        </div>
        <div className="col s12 m6">
          <span className="flow-text">
            <Carousel />
          </span>
        </div>
      </div>
      <IconContainer />
      <Map />
      <CnpfSait />
      <p></p>
    </main>
  );
};

export default Main;
