import React from "react";
import { LanguageProvider } from "./Header/LanguageContext";
import NavBar from "./Header/NavBar";
import Header from "./Header/Header";
import Main from "./Main";
import Footer from "./Footer";

function App() {
  return (
    <LanguageProvider>
      <div className="App">
        <>
          <NavBar />
          <Header />
          <Main />
          <Footer />
        </>
      </div>
    </LanguageProvider>
  );
}

export default App;
