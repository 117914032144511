import React from "react";

function Map() {
  return (
    <div className="row center-align">
      <div className="row s10 m6">
        <div className="video-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d961.6782396322398!2d28.847415781472332!3d47.01988230944529!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2sru!4v1676013688309!5m2!1sru!2sru"
            title="Google Map"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Map;
