import React, { useEffect } from "react";
import { useLanguage } from "./Header/LanguageContext";

const Carousel = () => {
  const { language } = useLanguage();

  useEffect(() => {
    const options = {
      fullWidth: true,
      indicators: true,
    };
    const elems = document.querySelectorAll(".carousel");
    window.M.Carousel.init(elems, options);
  }, []);

  return (
    <div className="carousel carousel-slider center">
      <div className="carousel-fixed-item center"></div>
      <div className="carousel-item red white-text" href="#one!">
        <h2>{language === "RU" ? "Кредит" : "Credit"}</h2>
        <p className="white-text">
          {language === "RU"
            ? "Моментально доступные финансовые решения"
            : "Soluții financiare disponibile instantaneu"}
        </p>
      </div>

      <div className="carousel-item amber white-text" href="#two!">
        <h2>{language === "RU" ? "Страхование" : "Asigurare"}</h2>
        <p className="white-text">
          {language === "RU"
            ? "Защита вашего автомобиля"
            : "Protejarea mașinii"}
        </p>
        <p className="white-text">
          {language === "RU" ? (
            <a
              href="/autorizatie bancassurance.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Подтверждение о регистрации в Реестре страховых компаний
            </a>
          ) : (
            <a
              href="/autorizatie bancassurance.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Confirmarea înregistrării în registrul societăților de asigurare
            </a>
          )}
        </p>
        <p className="white-text">
          {language === "RU" ? (
            <a
              href="/Servicii asigurare bancassurance.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Документация обмена
            </a>
          ) : (
            <a
              href="/Servicii asigurare bancassurance.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Partajare Documentation
            </a>
          )}
        </p>
      </div>
      <div className="carousel-item green white-text" href="#three!">
        <h2>{language === "RU" ? "Гарантия" : "Garanție"}</h2>
        <p className="white-text">
          {language === "RU"
            ? "Надежное оформление, модификация и закрытие сделок по залогу"
            : "Înregistrarea, modificarea și închiderea fiabilă a tranzacțiilor colaterale"}
        </p>
      </div>
    </div>
  );
};

export default Carousel;
