import React from "react";
import { useLanguage } from "./Header/LanguageContext";

const Footer = () => {
  const { language } = useLanguage();

  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="page-footer orange accent-3">
      <div className="container">
        <div className="row">
          <div className="col l6 s12">
            <h5 className="black-text">
              asicredinvest.md
              {language === "RU"
                ? " - кредит наличными"
                : " - împrumut în numerar"}
            </h5>
            <p className="grey-text text-lighten-4">
              {language === "RU" ? "Почта для связи: " : "Adresa de email: "}
              oficeaci@gmail.com
            </p>
          </div>
          <div className="col l4 offset-l2 s12">
            <h5 className="black-text">
              {language === "RU" ? "Ссылки" : "Links"}
            </h5>
            <ul>
              <li>
                <a className="grey-text text-lighten-3" href="#!">
                  YouTube
                </a>
              </li>
              <li>
                <a className="grey-text text-lighten-3" href="#!">
                  Tiktok
                </a>
              </li>
              <li>
                <a className="grey-text text-lighten-3" href="#!">
                  Instagram
                </a>
              </li>
              <li>
                <a className="grey-text text-lighten-3" href="#!">
                  Vk
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          © 2020 - {year}
          <a className="grey-text text-lighten-4 right" href="asicredinvest.md">
            asicredinvest.md
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
