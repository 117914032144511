import React from "react";
import { useLanguage } from "./Header/LanguageContext";

const CnpfSait = () => {
  const { language } = useLanguage();

  return (
    <div className="container">
      <div className="section">
        <div className="row">
          <p>
            În conformitate cu prevederile art. 3 din Legea nr. 192/1998 privind
            Comisia Naţională a Pieţei Financiare, CNPF are drept obiective de
            bază, inclusiv protejarea drepturilor consumatorilor de servicii
            financiare, în limitele stabilite de legislaţie.
          </p>

          <p>
            Potrivit art. 6 din Legea nr. 105/2003 privind protecția
            consumatorilor, orice consumator are dreptul la adresare în
            autorităţile publice şi reprezentarea în ele a intereselor sale.
            Totodată, art. 25 alin. (12) din Legea nr. 105/2003, atribuie
            agenților economici obligaţia să afişeze la vedere adresa şi numărul
            de telefon al autorităţii abilitate cu funcţii de protecţie a
            consumatorilor.
          </p>

          <p>
            Astfel, având în vedere atribuirea CNPF a competențelor conexe
            protecției drepturilor consumatorilor de servicii și produse
            financiare, în scopul realizării obiectivelor sale și asigurării
            respectării dreptului consumatorului de a fi informat corect,
          </p>

          <p>
            <strong>
              Aducem la cunoștință pentru clienți datele de contact ale
              autorității de supraveghere, și anume:
            </strong>
          </p>

          <ul>
            <li>denumirea - Comisia Națională a Pieței Financiare;</li>
            <li>
              adresa de contact - mun. Chișinău, bd. Ștefan cel Mare și Sfânt
              77, <a href="http://www.cnpf.md">www.cnpf.md</a>.
            </li>
          </ul>

          <p>
            <strong>Atragem atenția că reclamațiile pot fi depuse:</strong>
          </p>

          <ul>
            <li>
              prin e-mail (cu respectarea reglementărilor cu privire la forma
              electronică – cu aplicarea 2 semnăturii electronice) la adresa{" "}
              <a href="mailto:office@cnpf.md">office@cnpf.md</a>;
            </li>
            <li>
              sau prin intermediul oficiilor poștale: la sediul CNPF, în cutia
              poștală amplasată la intrare în sediul instituției (mun. Chișinău,
              bd. Ștefan cel Mare și Sfânt 77”;
            </li>
            <li>
              telefonul consumatorului - 022 85 95 95, (ATENȚIE!!! Tariful către
              acest număr de apel va fi considerat apel cu tarif normal conform
              rețelei și tipului de abonament al inițiatorului).
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CnpfSait;
